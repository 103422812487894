// sendFollowUser.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import login from '../action/login.js';
import { getReturnType } from '../action/fetchFeeds.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  MERGE_USER,
  CLEAR_NETWORKING_NODES,
} from '../ActionTypes.js';
import { POST_FEED } from '../RemoteConfigKeys.js';
import pushToastr from '../action/pushToastr.js';

/**
 * Send follow user
 * @kind action
 * @param {string} {id} - user id.
 * @return {Promise} Action promise.
 */
const sendFollowUser =
  ({ id }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token) {
      return dispatch(login());
    }

    const selectPath = ['follow', id];
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    const fetchOptions = {
      method: 'PUT',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const url = getResourceUrl({ endpoint: `/users/${id}/follow` });
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      dispatch({
        type: MERGE_USER,
        payload: { user: { isFollowing: true, id } },
      });
      // Fetch feed has 1 minute cache, clear networking after follow/unfollow can fix user not fetch latest feed bug.
      const feedName = getRemoteConfigData(getState(), POST_FEED);
      dispatch({
        type: CLEAR_NETWORKING_NODES,
        payload: {
          selectPaths: [
            [
              'home',
              getReturnType({
                feedName,
              }),
              feedName,
            ],
          ],
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      let errorObject = {};
      try {
        errorObject = JSON.parse(error.message);
        // eslint-disable-next-line no-empty
      } catch (_) {}

      if (errorObject.status === 403) {
        dispatch(
          pushToastr({
            textKey: 'something_went_wrong_block',
            color: 'error',
          })
        );
      }
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default sendFollowUser;
